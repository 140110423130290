import React, { useEffect, useState } from "react";
// import about from '../images/About.png'
// import about1 from '../images/image 1.png'
// import about2 from '../images/image 2.png'
// import about3 from '../images/image  3.png'
import "../CSS/AboutUs.css";
import BreadcrumbNav from "./BreadcrumbNav";
import { ApiWrt, BearerToken } from "../Tokens";

function AboutUs() {
  const [Data, setData] = useState({
    page_content: "",
  });
  useEffect(() => {
    const BToken = BearerToken();
    const ApiUrl = ApiWrt();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + BToken);

    var formdata = new FormData();
    formdata.append("access_key", "5670");
    formdata.append("language_id", localStorage.getItem("default_language_id"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${ApiUrl}/get_pages`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        let aboutUs = result.data.filter((data) => data.title === "About Us");
        setData(aboutUs[0]);
      })
      .catch((error) => console.log("error", error));
  }, []);
  console.log("firstdatainthe array", Data);
  return (
    <>
      <BreadcrumbNav SecondElement="About Us" ThirdElement="0" />
      <div className="main-aboutus">
        <div id="about-us">
          {/*<div>*/}
          {/*    <img src={about} className="img-fluid" alt="..."/>*/}
          {/*</div>*/}
          <div id="about">
            <h2 className="A my-4" style={{ color: "#1B2D51" }}>
              <strong> About Us </strong>
            </h2>
            <p dangerouslySetInnerHTML={{ __html: Data.page_content }} />
          </div>
        </div>
      </div>
      {/*<div id='our-company'>*/}
      {/*    <div className='img-wrapper'>*/}
      {/*        <div id='i1'>*/}
      {/*            <img src={about1} alt="..." id="image1"/>*/}
      {/*        </div>*/}
      {/*        <div id='i2'>*/}
      {/*            <img src={about3} alt="..." id="image2"/>*/}
      {/*        </div>*/}
      {/*        <div id='i3'>*/}
      {/*            <img src={about2} alt="..." id="image3"/>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*    <div className='container A3'>*/}
      {/*        <h1> About Our Company</h1>*/}
      {/*        <p className='p'> Our company that provide multi-technology services with multi-skilled and highly competent work force and strong globle presence.<br/><br/>*/}
      {/*            Our motto of help the customer to expand their business with help of technology. Yes, we aren't alone, We are team of Devlopers & Thechnology lovers who are Enthusiastic, Passionate, Skilled, Creative, Multi-Telented, Ready to Strive,Helpful & always there to support our lovable clients, who are integral part of our team. </p>*/}
      {/*    </div>*/}
      {/*</div>*/}
    </>
  );
}

export default AboutUs;
