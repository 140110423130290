import { GiHamburgerMenu } from "react-icons/gi";
import React, { useEffect, useState, useRef } from "react";
import "../CSS/Nav.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { BiBell, BiUserCircle } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import SignIn_Modal from "./SignIn_Modal";
import { getAuth, signOut, sendPasswordResetEmail } from "firebase/auth";

import News_main_Logo from "../images/logo.svg";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dropdown from "react-bootstrap/Dropdown";
import { ApiWrt, BearerToken } from "../Tokens";
import CatNav from "./CatNav";

const Newsbar = (props) => {
  const auth = getAuth();
  const [Data, setData] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [currentLang, setCurrentLang] = useState([]);
  const BToken = BearerToken();
  const [modalShow, setModalShow] = React.useState(false);
  const [islogout, setIsLogout] = useState(false);
  const [isloginloading, setisloginloading] = useState(true);
  const [ShowManu, setShowManu] = useState();
  const [info, setInfo] = useState([]);
  //const counterBadgeData = useSelector(counterData);
  const [click, setclick] = useState(false);
  const ApiUrl = ApiWrt();

  const navigate = useNavigate();
  const closeRef = useRef();
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + BToken);

    var formdata = new FormData();
    formdata.append("access_key", "5670");
    formdata.append("offset", "0");
    formdata.append("limit", "43");
    formdata.append("language_id", localStorage.getItem("default_language_id"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${ApiUrl}/get_category`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => console.log("error", error));

    let formdata2 = new FormData();
    formdata2.append("access_key", "5670");
    var myHeaders2 = new Headers();
    myHeaders2.append("Authorization", "Bearer " + BToken);

    fetch(`${ApiUrl}/get_languages_list`, {
      method: "POST",
      body: formdata2,
      headers: myHeaders2,
    })
      .then((response) => response.json())
      .then((result) => {
        const hindiLanguage = result.data.find(
          (language) => language.language === "Hindi"
        );

        if (hindiLanguage) {
          setLanguages([hindiLanguage]);
          localStorage.setItem("default_language", hindiLanguage.language);
          localStorage.setItem("default_language_id", hindiLanguage.id);
          setCurrentLang(hindiLanguage.language);
        }
      })
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    if (localStorage.getItem("user") !== null) {
      setIsLogout(true);
      setisloginloading(false);
    } else {
      setIsLogout(false);
      setisloginloading(true);
    }
  }, []);

  const logout = () => {
    handleClose();

    confirmAlert({
      title: "Logout",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            signOut(auth)
              .then(() => {
                localStorage.removeItem("token");
                localStorage.removeItem("user");

                setIsLogout(false);
                navigate("/");
              })
              .catch((error) => {
                alert(error);
                // An error happened.
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const changePassword = () => {
    sendPasswordResetEmail(
      auth,
      JSON.parse(localStorage.getItem("user")).data.email
    )
      .then((userCredential) => {
        // Signed in s
        alert("email sent Successfully");
        handleClose();
        // ...
      })
      .catch((error) => {
        console.log(error);
        // ..
      });
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const setCurrentLanguage = (data) => {
    setCurrentLang(data.language);
    console.log(data.language);
    localStorage.setItem("default_language", data.language);
    localStorage.setItem("default_language_id", data.id);
  };

  const fetchinfodata = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + BToken);

    var formdata = new FormData();
    formdata.append("access_key", "5670");
    formdata.append("offset", "0");
    formdata.append("limit", "100");
    formdata.append("language_id", localStorage.getItem("default_language_id"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${ApiUrl}/get_notification?access_key=5670&offset=0&limit=20`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setInfo(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    fetchinfodata();
  }, []);

  /*----------------------------slicing thwe  value on the basis of media query starts---------------*/
  const [sliceValue, setSliceValue] = useState(2); // Default slice value

  // Define breakpoints and corresponding slice values
  const breakpoints = [
    { width: 200, sliceValue: 2 },
    { width: 280, sliceValue: 2 },

    { width: 320, sliceValue: 3 },
    { width: 375, sliceValue: 3 },
    { width: 414, sliceValue: 3 },

    { width: 480, sliceValue: 5 },

    { width: 540, sliceValue: 5 },
    { width: 600, sliceValue: 6 },
    // { width: 768, sliceValue: 7 },
    { width: 820, sliceValue: 6 },
  ];
  // Function to update slice value based on screen width
  const updateSliceValue = () => {
    const screenWidth = window.innerWidth;
    const matchedBreakpoint = breakpoints.find((bp) => screenWidth <= bp.width);
    if (matchedBreakpoint) {
      setSliceValue(matchedBreakpoint.sliceValue);
    }
  };

  // Use useEffect to listen for window resize
  useEffect(() => {
    updateSliceValue(); // Set the initial slice value based on the screen width

    const handleResize = () => {
      updateSliceValue();
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /*----------------------------slicing thwe  value on the basis of media query ends---------------*/

  /*---------------------setting  catnav by in order  starts -----------------------*/

  function swapCategoryToIndex(categories, categoryName, targetIndex) {
    const categoryIndex = categories.findIndex(
      (category) => category.category_name.trim() === categoryName
    );

    if (categoryIndex !== -1) {
      const temp = categories[targetIndex];
      categories[targetIndex] = categories[categoryIndex];
      categories[categoryIndex] = temp;
    }
  }

  // Assuming you have the 'Data' array of objects
  swapCategoryToIndex(Data, "टॉप न्यूज़", 0);
  swapCategoryToIndex(Data, "देश", 1);
  swapCategoryToIndex(Data, "मध्य प्रदेश", 2);
  swapCategoryToIndex(Data, "छिंदवाड़ा", 3);

  function OffCanvasExample({ name, ...props }) {
    return (
      <>
        <button className="btn" onClick={handleShow}>
          <GiHamburgerMenu />
        </button>

        <Offcanvas
          id="Nav-Offcanvas"
          show={show}
          onHide={handleClose}
          {...props}
        >
          <Offcanvas.Header closeButton ref={closeRef}>
            <Offcanvas.Title>
              <li id="Nav-btns"></li>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="">
              <li className="nav-item">
                <b>
                  <Link
                    id="nav-links"
                    className=""
                    aria-current="page"
                    to="/"
                    onClick={handleClose}
                  >
                    HOME
                  </Link>
                </b>
              </li>
              <li className="nav-item">
                <b>
                  <Link
                    id="nav-links"
                    className=""
                    aria-current="page"
                    to="/about_us"
                    onClick={handleClose}
                  >
                    ABOUT US
                  </Link>
                </b>
              </li>
              <li className="nav-item">
                <b>
                  <Link
                    id="nav-links"
                    className=""
                    aria-current="page"
                    to="/LiveNews"
                    onClick={handleClose}
                  >
                    LIVE NEWS
                  </Link>
                </b>
              </li>

              <li className="nav-item">
                <b>
                  <Link
                    id="nav-links"
                    className=""
                    aria-current="page"
                    to="/BreakingNews  "
                    onClick={handleClose}
                  >
                    BREAKING NEWS
                  </Link>
                </b>
              </li>
              <li className="nav-item">
                <b>
                  <Link
                    id="nav-links"
                    className=""
                    aria-current="page"
                    to="/Contact_us"
                    onClick={handleClose}
                  >
                    CONTACT US
                  </Link>
                </b>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }

  return (
    <div className="navbar-container padbotmor">
      <nav className="Newsbar">
        <div id="News-logo" className="News-logo">
          <Link to="/">
            <img id="NewsLogo" src={News_main_Logo} alt="" />
          </Link>
        </div>

        <div className="Manu-links">
          <ul className="">
            <li id="NavHover" className="nav-item">
              <b>
                <Link
                  id="nav-links"
                  className="link-color"
                  aria-current="page"
                  to="/"
                >
                  HOME
                </Link>
              </b>
            </li>
            <li id="NavHover" className="nav-item">
              <b>
                <Link
                  id="nav-links"
                  className="link-color"
                  aria-current="page"
                  to="/about_us"
                >
                  ABOUT US
                </Link>
              </b>
            </li>

            <li id="NavHover" className="nav-item">
              <b>
                <Link
                  id="nav-links"
                  className="link-color"
                  aria-current="page"
                  to="/CategoryView?id=6&uid=1"
                >
                  BREAKING NEWS
                </Link>
              </b>
            </li>
            <li id="NavHover" className="nav-item">
              <b>
                <Link
                  id="nav-links"
                  className="link-color"
                  aria-current="page"
                  to="/Contact_us"
                >
                  CONTACT US
                </Link>
              </b>
            </li>

            <li id="Nav-btns">
              <Link
                to="/News_Notification"
                id="btnNotification"
                type="button"
                className="btn"
              >
                <BiBell size={23} />
              </Link>
            </li>
          </ul>

          <SignIn_Modal
            setIsLogout={setIsLogout}
            setisloginloading={setisloginloading}
            show={modalShow}
            setLoginModalShow={setModalShow}
            onHide={() => setModalShow(false)}
          />
        </div>
        <div className="hamburger-manu" style={{ alignItems: "center" }}>
          <Link
            style={{ width: "8px", height: "20px", position: "relative" }}
            to="/News_Notification"
            id="btnNotification"
            type="button"
            className="btn"
          >
            <BiBell
              size={15}
              style={{ position: "absolute", left: "4px", top: "3px" }}
            />
          </Link>

          {["end"].map((placement, idx) => (
            <OffCanvasExample
              key={idx}
              placement={placement}
              name={placement}
            />
          ))}
        </div>
      </nav>
      <div className="navheadnoWrapper" style={{ boxSizing: "border-box" }}>
        <div className="navheadno">
          <ul
            style={{
              display: "flex",
              justifyContent: "space-between ",

              alignItems: "baseline",
            }}
          >
            {Data &&
              Data.slice(0, sliceValue).map((element, index) => (
                <li style={{ listStyle: "none" }} className="nitem" key={index}>
                  <Link
                    id="catNav-links"
                    to={`/CategoryView?id=${element.id}&uid=1`}
                    onClick={handleClose}
                  >
                    <b
                      className="catewrp"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {element.category_name}
                    </b>
                  </Link>
                </li>
              ))}

            <div>
              {Data.length > 10 && (
                <>
                  <button
                    className="butfnsz"
                    id="catNav-links"
                    style={{
                      border: "none",
                      background: "transparent",
                    }}
                    onClick={() => {
                      navigate("/categories");
                    }}
                  >
                    More
                  </button>
                </>
              )}
            </div>
          </ul>
        </div>
      </div>

      <div className={ShowManu ? "mobile-manu2" : "mobile-manu"}></div>
    </div>
  );
};

export default Newsbar;
