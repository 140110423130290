import React, { useState, useEffect } from "react";
import "../CSS/categories.css";
import { Link } from "react-router-dom";
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import Offcanvas from "react-bootstrap/Offcanvas";
import ReactPaginate from "react-paginate";
import BreadcrumbNav from "./BreadcrumbNav";
import { ApiWrt, BearerToken } from "../Tokens";

function Categories(props) {
  const [show, setShow] = useState(true);
  const [Data, setData] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [offsetdata, setOffsetdata] = useState(0);
  const limit = 12;
  const BToken = BearerToken();
  const ApiUrl = ApiWrt();

  useEffect(() => {
    fetchData(0);
  }, []);

  const fetchData = (offset) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + BToken);

    var formdata = new FormData();
    formdata.append("access_key", "5670");
    formdata.append("offset", offset);
    formdata.append("limit", limit);
    formdata.append("language_id", localStorage.getItem("default_language_id"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${ApiUrl}/get_category`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        setTotalLength(result.total);
      })
      .catch((error) => console.log("error", error));
  };
  const handlePageChange = (selectedPage) => {
    const newOffset = selectedPage.selected * limit;
    setOffsetdata(newOffset);
    fetchData(newOffset);
  };
  const newData = [...Data];

  const indexToMove = newData.findIndex(
    (item) => item.category_name === "मध्य प्रदेश"
  );

  if (indexToMove !== -1) {
    const movedObject = newData.splice(indexToMove, 1)[0];

    newData.splice(0, 0, movedObject);
  }

  // console.log("newDatasdasrs", newData);

  return (
    <>
      <BreadcrumbNav SecondElement="Categories" ThirdElement="0" />

      <div className="container my-5">
        <div className="row">
          {newData &&
            newData.map((element) => (
              <div className="col-md-4 col-12 mb-4" key={element.id}>
                <Link
                  id="cat-section-card"
                  className="card"
                  to={`/CategoryView?id=${element.id}&uid=1`}
                >
                  <img
                    id="cat-section-card-image"
                    src={element.image}
                    className="card-img"
                    alt="..."
                  />
                  <div id="cat-section-card-body" className="card-img-overlay">
                    <h5 id="cat-card-text" className="card-text mb-0">
                      {element.category_name}
                    </h5>
                    <button id="btn-cat-more" className="btn" type="button">
                      <IoArrowForwardCircleSharp size={40} />
                    </button>
                  </div>
                </Link>
              </div>
            ))}
        </div>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel="..."
          breakClassName="break-me"
          pageCount={Math.ceil(totalLength / limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link prevnext"}
          nextLinkClassName={"pagination__link prevnext"}
          disabledClassName={"pagination__link--disabled disabcls"}
          activeClassName={"pagination__link--active actcls"}
        />
      </div>
    </>
  );
}

export default Categories;
