import React, { useState, useEffect } from "react";
import { Form, Link, useLocation } from "react-router-dom";
import { ApiWrt, BearerToken } from "../Tokens";
import BreadcrumbNav from "./BreadcrumbNav";
import { AiOutlineLike, AiTwotoneLike } from "react-icons/ai";
import { FiCalendar } from "react-icons/fi";
import {
  FacebookIcon,
  WhatsappIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookShareButton,
} from "react-share";
import { useQuery } from "../Hooks";
import CommentSection from "./CommentSection";
import RelatedNewsSection from "./RelatedNewsSection";
import TagsSection from "./TagsSection";
import FormLabel from "react-bootstrap/esm/FormLabel";
import FormRange from "react-bootstrap/esm/FormRange";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";

function SingleNews() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const d = queryParams.get("d");
  const [Data, setData] = useState([]);
  const ApiUrl = ApiWrt();
  const BToken = BearerToken();

  /*----------------------------------------*/
  const [CheckLike, setCheckLike] = useState(false);
  const [Like, setLike] = useState(CheckLike);
  const [Bookmark, setBookmark] = useState(false);
  const [FontSize, setFontSize] = useState(14);
  const [FontSizeCss, setFontSizeCss] = useState("14px");
  const query = useQuery();
  const Nid = query.get("Nid");
  const catid = query.get("Cid");
  const shareUrl = window.location.href;

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + BToken);

    var formdata = new FormData();
    formdata.append("access_key", "5670");
    formdata.append("offset", "0");
    formdata.append("limit", "100");
    formdata.append("news_id", Nid);

    formdata.append("language_id", localStorage.getItem("default_language_id"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${ApiUrl}/get_notification?access_key=5670&offset=0&limit=20`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => console.log("error", error));
  }, [ApiUrl, BToken, Nid]);

  return (
    <>
      <div>
        {Data.map((mitem, iodex) => {
          if (d === mitem.id) {
            return (
              <>
                {" "}
                <div>
                  <BreadcrumbNav
                    SecondElement="News Details"
                    ThirdElement={mitem.title}
                  />
                  <div id="nv-main">
                    <div id="nv-page">
                      <div id="nv-body">
                        <button
                          id="btnnvCatagory"
                          className="btn btn-sm"
                          type="button"
                        >
                          {mitem.category_name}
                        </button>
                        <h1 id="nv-title">{mitem.title}</h1>
                        <div id="nv-Header" className="">
                          <div id="nv-left-head">
                            <p id="head-lables">
                              <FiCalendar size={18} id="head-logos" />{" "}
                              {mitem.date_sent.slice(0, 10)}
                            </p>
                            <p id="head-lables">
                              <AiOutlineLike size={18} id="head-logos" />{" "}
                              {mitem.total_like} Likes
                            </p>
                          </div>
                          <div id="nv-right-head">
                            <h6 id="nv-Share-Label">SHARE:</h6>
                            <FacebookShareButton
                              url={shareUrl}
                              title={mitem.title + " - Enews"}
                              hashtag={"Enews"}
                            >
                              <FacebookIcon size={40} round />
                            </FacebookShareButton>
                            <WhatsappShareButton
                              url={shareUrl}
                              title={mitem.title + " - Enews"}
                              hashtag={"Enews"}
                            >
                              <WhatsappIcon size={40} round />
                            </WhatsappShareButton>
                            <TwitterShareButton
                              url={shareUrl}
                              title={mitem.title + " - Enews"}
                              hashtag={"Enews"}
                            >
                              <TwitterIcon size={40} round />
                            </TwitterShareButton>
                            <Link></Link>
                          </div>
                        </div>

                        <img id="nv-image" src={mitem.image} alt="..." />
                        <nav id="nv-functions" className="">
                          <div id="nv-functions-left">
                            <FormLabel id="nv-font-lable">Font Size</FormLabel>
                            <FormRange
                              id="nv-FontRange"
                              min={14}
                              max={24}
                              step={2}
                              value={FontSize}
                              onChange={(e) => setFontSize(e.target.value)}
                            />
                            <div className="d-flex justify-content-between">
                              <FormCheckLabel id="nv-FontRange-labels">
                                14px
                              </FormCheckLabel>
                              <FormCheckLabel id="nv-FontRange-labels">
                                16px
                              </FormCheckLabel>
                              <FormCheckLabel id="nv-FontRange-labels">
                                18px
                              </FormCheckLabel>
                              <FormCheckLabel id="nv-FontRange-labels">
                                20px
                              </FormCheckLabel>
                              <FormCheckLabel id="nv-FontRange-labels">
                                22px
                              </FormCheckLabel>
                              <FormCheckLabel id="nv-FontRange-labels">
                                24px
                              </FormCheckLabel>
                            </div>
                          </div>
                        </nav>

                        <p
                          id="nv-description"
                          style={{ fontSize: `${FontSize}px` }}
                          dangerouslySetInnerHTML={{
                            __html: mitem.message,
                          }}
                        ></p>
                        <CommentSection Nid={Nid} />
                      </div>
                      <div id="nv-right-section">
                        <RelatedNewsSection Cid={catid} />
                        <TagsSection />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          }
          return null; // No match, return null
        })}
      </div>
      <div></div>
    </>
  );
}

export default SingleNews;
