import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../CSS/Catnav.css";
import { Link, useNavigate } from "react-router-dom";
import { ApiWrt, BearerToken } from "../Tokens";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

function CatNav() {
  const [Data, setData] = useState([]);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [isChindwaraDialogOpen, setIsChindwaraDialogOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const BToken = BearerToken();
  const ApiUrl = ApiWrt();
  const navigate = useNavigate();

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + BToken);

    var formdata = new FormData();
    formdata.append("access_key", "5670");
    formdata.append("offset", "0");
    formdata.append("limit", "43");
    formdata.append("language_id", localStorage.getItem("default_language_id"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${ApiUrl}/get_category`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const handleMouseEnter = (element) => {
    setHoveredCategory(element);
  };

  const handleMouseLeave = () => {
    setHoveredCategory(null);
  };

  const closeChindwaraDialog = () => {
    setIsChindwaraDialogOpen(false);
  };

  const handleSubCategoryClick = (event) => {
    event.stopPropagation();
  };

  function swapCategoryToIndex(categories, categoryName, targetIndex) {
    const categoryIndex = categories.findIndex(
      (category) => category.category_name.trim() === categoryName
    );

    if (categoryIndex !== -1) {
      const temp = categories[targetIndex];
      categories[targetIndex] = categories[categoryIndex];
      categories[categoryIndex] = temp;
    }
  }

  swapCategoryToIndex(Data, "टॉप न्यूज़", 0);
  swapCategoryToIndex(Data, "देश", 1);
  swapCategoryToIndex(Data, "मध्य प्रदेश", 2);
  swapCategoryToIndex(Data, "छिंदवाड़ा", 3);

  return (
    <div>
      <Navbar id="cn-main" expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="me-auto"
              style={{
                width: "100%",
                /* background-color: red; */
                justifyContent: " space-between",
                alignItems: "baseline",
              }}
            >
              {Data &&
                Data.slice(0, 10).map((element) => (
                  <div
                    style={{ position: "relative" }}
                    key={element.id}
                    onMouseEnter={() => handleMouseEnter(element)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <b
                      className="bcategory"
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: "0.8rem",
                        cursor: "pointer",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: " #1b2d51" }}
                        to={`/CategoryView?id=${element.id}&uid=1`}
                      >
                        {selectedCategory === "छिंदवाड़ा"
                          ? selectedCategory
                          : element.category_name}
                      </Link>
                    </b>
                    {element.category_name === "छिंदवाड़ा" && (
                      <span
                        onClick={() => setIsChindwaraDialogOpen(true)}
                        style={{
                          cursor: "pointer",
                          right: "12px",
                          fontSize: "18px",
                        }}
                        onMouseEnter={handleMouseLeave}
                      ></span>
                    )}

                    {hoveredCategory === element && element.subcategory && (
                      <ul
                        style={{
                          listStyleType: "none",
                          padding: 0,
                          cursor: "pointer",
                          backgroundColor: "white",
                          border: "1px solid #ccc",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          position: "absolute",
                          width: "89px",
                          zIndex: "999",
                          left: "-15px",
                        }}
                      >
                        {element.subcategory.map((subCategory) => (
                          <li
                            key={subCategory.id}
                            onClick={handleSubCategoryClick}
                          >
                            <Link
                              style={{ textDecoration: "none", color: "black" }}
                              to={`/getsubcategory?subcategoryId=${subCategory.id}`}
                            >
                              {subCategory.subcategory_name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              {Data.length > 10 && (
                <>
                  <button
                    id="catNav-links"
                    style={{ border: "none", backgroundColor: "white" }}
                    onClick={() => {
                      navigate("/categories");
                    }}
                  >
                    More
                  </button>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Dialog
        open={isChindwaraDialogOpen}
        onClose={closeChindwaraDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Select City"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ width: "320px" }}>
              <>
                <div className="container my-5">
                  <div className="row">
                    {Data.filter(
                      (category) =>
                        category.category_name === "मध्य प्रदेश" ||
                        category.category_name === "छिंदवाड़ा" ||
                        category.category_name === "कटनी" ||
                        category.category_name === "नरसिंहपुर" ||
                        category.category_name === "सिवनी" ||
                        category.category_name === "बालाघाट" ||
                        category.category_name === "मंडला" ||
                        category.category_name === "डिंडोरी" ||
                        category.category_name === "Katni" ||
                        category.category_name === "Narsinghpur" ||
                        category.category_name === "Seoni" ||
                        category.category_name === "Chhindwara" ||
                        category.category_name === "Balaghat" ||
                        category.category_name === "Mandla" ||
                        category.category_name === "Dindori"
                    ).map((category) => (
                      <div className="col-md-4 col-12 mb-4">
                        <Link
                          id="cat-section-card"
                          key={category.id}
                          className="card"
                          to={`/CategoryView?id=${category.id}&uid=1`}
                        >
                          <img
                            id="cat-section-card-image"
                            src={category.image}
                            className="card-img"
                            alt="..."
                          />
                          <div
                            id="cat-section-card-body"
                            className="card-img-overlay"
                          >
                            <h5 id="cat-card-text" className="card-text mb-0">
                              {category.category_name}
                            </h5>
                            <button
                              id="btn-cat-more"
                              className="btn"
                              type="button"
                            ></button>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeChindwaraDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <></>
    </div>
  );
}

export default CatNav;
