import React, { useState, useEffect } from "react";
// import { AiOutlineLike, AiFillLike } from 'react-icons/ai';
import { FiTrash2 } from "react-icons/fi";

import "../CSS/Notification.css";
import { Link } from "react-router-dom";
import Spinner from ".//Spinner";
import { ApiWrt, BearerToken } from "../Tokens";
import BreadcrumbNav from "./BreadcrumbNav";
import Newsbar from "./Newsbar";

function NewsNotification() {
  const [Data, setData] = useState([]);
  const ApiUrl = ApiWrt();
  const BToken = BearerToken();
  const handleDeleteComment = (id) => {
    var uid = JSON.parse(localStorage.getItem("user")).data.id;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + BToken);
    myHeaders.append(
      "Cookie",
      "ci_session=12af9107c7cb1f15a290434b44c1be817b862317; csrf_cookie_name=2edd6e5df33b18ac19c9b5bed190f876"
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    var params = { access_key: 5670, user_id: uid, comment_id: id };

    var url = new URL(`${ApiUrl}/delete_comment`);
    for (let k in params) {
      url.searchParams.append(k, params[k]);
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert(result.message);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + BToken);

    var formdata = new FormData();
    formdata.append("access_key", "5670");
    formdata.append("offset", "0");
    formdata.append("limit", "100");
    formdata.append("language_id", localStorage.getItem("default_language_id"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${ApiUrl}/get_notification?access_key=5670&offset=0&limit=20`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <>
      <BreadcrumbNav SecondElement="Notification" />

      <div id="main-Noticard" className="my-3">
        <div className="d-flex bd-highlight mb-3">
          <Link
            to="/News_Notification"
            id="btnNewsnoti1"
            className="btn mx-1 bd-highlight"
          >
            {" "}
            News
          </Link>
        </div>
        <div className="my-3">
          <>
            {Data &&
              Data.map((d, index) => (
                <div className="card my-3" key={index}>
                  <Link
                    to={`/singlenews?d=${d.id}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <div className="card-body bd-highlight" id="card-noti">
                      <img id="noti_profile" src={d.image} alt="" />
                      <div className="Noti-text">
                        <p>{d.title.slice(0, 100)}...</p>
                        <p className="bd-highlight"> {d.message}...</p>
                        <p className="bd-highlight"> {d.date_sent}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </>
        </div>
      </div>
    </>
  );
}

export default NewsNotification;
