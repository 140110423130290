import React, { useState, useEffect } from "react";
import "../CSS/Footer.css";
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedin,
  FaTwitterSquare,
} from "react-icons/fa";
import Terms_Condition from "./Terms_Condition";
import Privacy_Policy from "./Privacy_Policy";
import { Link } from "react-router-dom";
import { ApiWrt, BearerToken } from "../Tokens";
import moment from "moment/moment";
import CatNav from "./CatNav";

function Footer() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [Privacy, setPrivacy] = useState(false);
  const handlePrivacy = () => setPrivacy(true);
  const [Data, setData] = useState([]);
  const BToken = BearerToken();
  const ApiUrl = ApiWrt();

  const initialValues = { email: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState(
    "",
    setTimeout(() => {
      if (formErrors !== "") setFormErrors("");
    }, 5000)
  );
  const [isSubcribe, setIsSubcribe] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    setFormValues({ ...formValues, [name]: value });
    console.log(formValues);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSubcribe = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubcribe(true);
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubcribe);
  }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^s\@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "Enter a Valid EMail";
    }

    return errors;
  };
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + BToken);

    var formdata = new FormData();
    formdata.append("access_key", "5670");
    formdata.append("offset", "0");
    formdata.append("limit", "9");
    formdata.append("language_id", localStorage.getItem("default_language_id"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${ApiUrl}/get_category`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <>
      <div className="full_footer">
        <section id="footer">
          <div className="container mx-5"></div>
          <div className="news_footer ">
            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3">
                <div className="News">
                  <h3>{process.env.REACT_APP_NAME}</h3>
                </div>
                <div className="my-5 Lorem-text">
                  <p className="lorem">
                    {process.env.REACT_APP_FOOTER_DESCRIPTION}
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3">
                <p id="footer-nav"> News Categories</p>
                {Data && Data.length > 0 ? (
                  <ul className="newscate">
                    {Data.map((value, index) => {
                      return (
                        <li key={index}>
                          <Link
                            to={"/CategoryView?id=" + value.id}
                            onClick={scrollToTop}
                          >
                            {value.category_name}{" "}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </div>

              <div className="col-xs-3 col-sm-3 col-md-">
                <p id="footer-nav">useful links</p>
                <ul className="useL">
                  <li>
                    <Link to="/" onClick={scrollToTop}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about_us" onClick={scrollToTop}>
                      About
                    </Link>
                  </li>

                  <li>
                    <Link to="/CategoryView?id=6&uid=1" onClick={scrollToTop}>
                      Breaking News
                    </Link>
                  </li>
                  <li>
                    <Link to="/Contact_us" onClick={scrollToTop}>
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className=" d-flex copyright" id="copyright1">
            <p id="footer-Copyright" className="h6 me-auto p-2">
              Copyright © {moment().year()}.All right Reserved By ABM News.
            </p>
            <p
              id="footer-tearms"
              className="h6 p-2"
              variant="primary"
              onClick={handleShow}
            >
              Terms & Conditions
            </p>
            <p className="h6 p-2"> | </p>
            <p
              id="footer-pri-pol"
              className="h6 p-2"
              variant="primary"
              onClick={handlePrivacy}
            >
              {" "}
              Privacy Policy{" "}
            </p>
          </div>
        </section>
      </div>
      <Terms_Condition show={show} setShow={setShow} />
      <Privacy_Policy Privacy={Privacy} setPrivacy={setPrivacy} />
    </>
  );
}
export default Footer;
