import React from "react";
import "../CSS/categoryview.css";
import { FiCalendar } from "react-icons/fi";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useQuery } from "../Hooks";
import { ApiWrt, BearerToken } from "../Tokens";
import { useParams, useLocation } from "react-router-dom";
import BreadcrumbNav from "./BreadcrumbNav";

function Getsubcategory() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subcategoryId = queryParams.get("subcategoryId");

  const [Data, setData] = useState([]);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [isChindwaraDialogOpen, setIsChindwaraDialogOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const BToken = BearerToken();
  const ApiUrl = ApiWrt();

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + BToken);

    var formdata = new FormData();
    formdata.append("access_key", "5670");
    formdata.append("offset", "0");
    formdata.append("limit", "16");
    formdata.append(" subcategory_id", subcategoryId);
    formdata.append(" user_id", "1");

    formdata.append("language_id", localStorage.getItem("default_language_id"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${ApiUrl}/get_news_by_category`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => console.log("error", error));
  }, [subcategoryId]);

  return (
    <>
      <BreadcrumbNav SecondElement="Category" ThirdElement="0" />

      <div id="cv-main">
        <div id="cv-content" className="">
          <h1 className="text-center"></h1>
          <div className="row">
            {Data &&
              Data.map((element) => (
                <div className="col-md-4 " key={element.id}>
                  <Link
                    id="Link-all"
                    to={`/NewsView?Nid=${element.id}&Cid=${element.subcategory_id}`}
                  >
                    <div id="cv-card" className="card">
                      <img
                        id="cv-card-image"
                        src={element.image}
                        className="card-img"
                        alt="..."
                      />
                      <div id="cv-card-body" className="card-body">
                        <button
                          id="cv-btnCatagory"
                          className="btn btn-sm"
                          type="button"
                        >
                          {element.title}
                        </button>
                        <p id="cv-card-title" className="card-title">
                          {element.title.slice(0, 150)}...
                        </p>
                        <p id="cv-card-date">
                          <FiCalendar size={18} id="cv-logoCalendar" />
                          {element.date.slice(0, 10)}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Getsubcategory;
